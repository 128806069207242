<template>
  <div>
    <CRow>
      <CCol sm="12">
        <BinnacleUsersTable
          :items="users"
          :fields="fields"
          hover
          striped
          border
          small
          fixed
          caption="Usuarios de Bitacora"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import BinnacleUsersTable from '../../components/binnacle/users/BinnacleUsersTable.vue'
import collaborators from '../../services/collaborators';
import account_ws from '../../services/account';
import store from '../../store'

export default {
  name: 'BinnacleUsers',
  components: { BinnacleUsersTable },
  data: function () {
		return {
            users: [],
            account: null,
            fields: [
              {key: 'name', label: 'Nombre'},
              {key: 'phone', label: 'Whatsapp'},
              {key: 'is_validator', label: 'Validador'},
              {key: 'actions', label: 'Acciones', class: 'actions' },
            ]
        }
  },
  mounted: async function() {
    this.loading();
    let response = await collaborators.getBinnacleUsers();

    if(response.type == "success"){
      this.users = response.data;
    }

    this.account = JSON.parse(localStorage.getItem("account"));

    if(this.account.has_validations){
      this.fields = [
        {key: 'name', label: 'Nombre'},
        {key: 'phone', label: 'Whatsapp'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ];
    }
    else{
      this.fields = [
        {key: 'name', label: 'Nombre'},
        {key: 'phone', label: 'Whatsapp'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ];
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.users = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
